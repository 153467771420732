import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const About = () => {
    useDocTitle('Tax-Alpha')

   
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">About us</h2>
                    <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>

                    <br />
                    <p>
                        Empowering Your Financial Success with Expert Tax Consultancy and Compliance services.
                    </p>
                    <br />

                    <p>
                        Welcome to Tax Alpha!
                    </p>
                    <br />
                    <p>
                        We are a team of qualified expert, experienced and dedicated professionals providing expert solutions in the field of Income tax, GST, Company Law and other compliances. With experience our team has developed a deep understanding of the constantly changing laws and regulations related to wide range of services we offer.
                    </p>
                    <br />
                    <p>
                        Our goal is to provide our clients with comprehensive and efficient tax and legal services that help them navigate the complex landscape of financial and legal compliance.
                    </p>
                    <br />
                    <p>
                        At Tax Alpha, we pride ourselves on delivering high-quality services that are tailored to meet the unique needs of each client. We understand that every business is different and has different needs, which is why we work closely with our clients to provide customized solutions that best meet their requirements.
                    </p>
                    <br />
                    <p>
                        We are committed to building strong and long-lasting relationships with our clients, based on trust, transparency and mutual respect. Our team of experts is always available to answer any questions you may have and to provide the guidance and support you need to achieve your financial and legal goals.
                    </p>
                    <br />
                    <p>
                        Thank you for considering us as the manager of your financial & regulatory compliance needs. We look forward to serving you!
                    </p>
                </div>
            </div>
            <Footer />
        </>


    )
}

export default About;