import React from 'react';

export const TestimonialsCard = ({ itm }) => {
  return (
    <>
      <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
        <div className="m-2 text-justify text-sm">
          <p className="text-md font-medium leading-5 h-auto md:h-48" style={{ overflow: 'hidden', padding: '0.7rem' }}>
            {itm.message}
          </p>
          <h4 className="font-semibold text-lg md:text-2xl text-right mt-2"> -- {itm.name}</h4>
          <p className="text-md font-medium leading-5 h-auto text-right">{itm.company ?? '-'}</p>
        </div>
      </div>
    </>
  );
};
