import React, { useEffect, useState } from 'react';
import {  createTestimonial, deleteTestimonial, getTestimonials, updateTestimonial } from '../utils/services';
import NavBarAdmin from '../components/Navbar/NavBarAdmin';
import CloseIcon from '../images/closeicon.svg';
import { TestimonialsCard } from '../components/TestimonialsCard';

const Testimonials = (props) => {
  const [plan, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    await getTestimonials().then(async (response) => {
      if (response.data) {
        setPlans(response.data);
      } else if (response.error) {
        setPlans([]);
      }
    });
  };

  const closePopup = () => {
    setShowModal(false);
    setSelectedPlan({});
  };

  const onUpdateVal = (e, name) =>
    setSelectedPlan((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));

  const handleClick = (plan) => {
    let newObj = plan;
    // newObj['items'].map((itm) => ({ label: itm.title, value: itm._id }));
    setSelectedPlan(newObj);
    setShowModal(true);
  };

  const updateNotes = async () => {
    let payload = { ...selectedPlan };

    if (payload._id) {
      await updateTestimonial(payload).then(async (response) => {
        closePopup();
        if (response.data) {
          fetchPlans();
        } else if (response.error) {
        }
      });
    } else {
      await createTestimonial(payload).then(async (response) => {
        closePopup();
        if (response.data) {
          fetchPlans();
        } else if (response.error) {
        }
      });
    }
  };

  const deletePlanFunc = async (plan) => {
    let payload = {
      _id: plan._id,
    };
    await deleteTestimonial(payload).then(async (response) => {
      closePopup();
      if (response.data) {
        fetchPlans();
      } else if (response.error) {
      }
    });
  };

  return (
    <>
      <div>
        <NavBarAdmin />
      </div>
      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Testimonials</h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <div className="ml-auto">
            <button
              className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-auto 
                                    focus:outline-none focus:shadow-outline"
              onClick={() => setShowModal(true)}
            >
              Create Testimonial
            </button>
          </div>
          <div className="px-12 mt-10" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {plan?.map((plan) => {
                return (
                  <div
                    onClick={() => {
                      handleClick(plan);
                    }}
                    className="cursor-pointer"
                  >
                    <TestimonialsCard itm={plan} />
                  </div>
                );
              })}
            </div>
          </div>

          {showModal ? (
            <>
              <div
                className="drop-shadow-2xl flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none w-50  size-full my-auto mt-2"
                style={{ height: 800 }}
                data-aos="fade-down"
                data-aos-delay="300"
              >
                <div className="relative mx-auto max-w-3xl md:max-w-2xl md:w-full m-3 md:mx-auto my-auto">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none md:max-w-2xl md:w-full m-3 md:mx-auto">
                    <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t w-auto ">
                      <h3 className="text-3xl font-semibold">{selectedPlan?._id ? 'Edit' : 'Create New'} Testimonial</h3>
                      {/* <button className="bg-transparent border-0 text-black float-right" onClick={() => setShowModal(false)}>
                        <span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full">x</span>
                      </button> */}
                      <img src={CloseIcon} className="h-6 cursor-pointer" onClick={() => closePopup()} alt="closeIocn" />
                    </div>
                    <div className="relative p-6 flex-auto">
                      <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
                        <label className="block text-black text-sm font-bold mb-2">Name</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={selectedPlan.name} onChange={(e) => onUpdateVal(e, 'name')} />
                        <label className="block text-black text-sm font-bold mb-2 mt-2">Message</label>
                        <textarea
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          value={selectedPlan.message}
                          onChange={(e) => onUpdateVal(e, 'message')}
                        />
                        <label className="block text-black text-sm font-bold mb-2 mt-2">Company Info</label>
                        <textarea
                          className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                          value={selectedPlan.company}
                          onChange={(e) => onUpdateVal(e, 'company')}
                        />
                      </form>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                      <button
                        className="text-black-500 bg-grey-100 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={() => closePopup()}
                      >
                        Close
                      </button>
                      <button
                        className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={() => updateNotes()}
                      >
                        Submit
                      </button>
                      {selectedPlan?._id && (
                        <button
                          className="text-white bg-red-500 active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          onClick={() => deletePlanFunc(selectedPlan)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
