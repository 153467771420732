import React, { useEffect } from 'react';
import { TestimonialsCard } from './TestimonialsCard';
import {  getTestimonials } from '../utils/services';

const Testimonial = () => {
  const [testimonials, setTestimonials] = React.useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await getTestimonials().then(async (response) => {
      if (response.data) {
        setTestimonials(response.data);
        // window.sessionStorage.setItem('services',JSON.stringify(response.data))
      } else if (response.error) {
        setTestimonials([]);
      }
    });
  };

  return (
    <>
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Testimonials</h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-5">
            {testimonials.map((rec,j) => {
              return (
                <React.Fragment key={j}>
                  <TestimonialsCard itm={rec} />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
