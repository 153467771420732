import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const isAuthenticated = JSON.parse(sessionStorage.getItem('user_info'));

  return isAuthenticated ? children : <Navigate to="/admin" />;
};

export default AdminRoute;
