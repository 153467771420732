import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const Privacy = () => {
  useDocTitle('Tax-Alpha');

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id="contact" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Privacy Policy</h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>

          <br />
          <p className='mt-4'>
            At Tax Alpha, we are deeply committed to protecting the personal information of our clients and others. Our dedication ensures full compliance with the Information
            Technology Act, 2000, its amendments, and all other relevant laws in India. Building and maintaining trust with our clients is our top priority.
          </p>
          <br />

          <p>
            This privacy policy outlines Tax Alpha's procedures for collecting and sharing personal information. It covers the data we collect and use for managing various income
            tax policies and providing services to our clients. These services include, but are not limited to, tax preparation, tax planning, tax scrutiny, addressing tax queries,
            and offering financial planning advisory services within the Indian context.
          </p>
          <br />
          <p>This Privacy policy applies to all the clients, advisers, visitors, service providers, enquirers, direct or indirect beneficiaries.</p>
          <br />
          <p>We may gather and process the subsequent personal information about you:</p>
          <br />

          <ul className="list-disc">
            <li className="ml-8">Provided Information: Information you willingly provide by completing forms or uploading data.</li>

            <li className="ml-8">
              Registration Details: To register, you are required to furnish personal identification details, including your name, address, contact information, email address, and
              a password. These details are essential for the provision of tax preparation services.
            </li>
            <li className="ml-8">
              Advisory Services: If you utilize our advisory services, this may encompass information regarding your instructions for such services, as well as the management and
              administration of those services. Additionally, it may include information related to any claims or complaints, including details provided by third parties.
            </li>
            <li className="ml-8">
              Tax-Related Information: To access tax-related services, you must provide a range of data, including (but not limited to) your name, address, email address, phone
              number, Permanent Account Number ("PAN"), bank account details, MICR Code, income and deductions data, Director Identification Number (DIN), expense data, asset and
              liability particulars, vouchers, bank statements, FORM 15CA, FORM 16, FORM 26AS, tax payment challans, debit and credit account numbers, along with their expiration
              details, and any other information necessary for the preparation of your tax return.
            </li>
            <li className="ml-8">
              Tax Scrutiny/Tax Notice Services: If you wish to avail of our Tax Scrutiny/Tax Notice services, we collect relevant information from you, including copies of notices
              or correspondence sent to you by the relevant department. This data is required for the preparation and submission of appropriate responses to such notices on your
              behalf.
            </li>
            <li className="ml-8">
              Communication Records: In the event of your contact with us, we may retain records of correspondence, emails, voicemails, or details of conversations conducted with
              you.
            </li>
            <li className="ml-8">
              Website Interaction: We collect details pertaining to your visits to our website. This includes information obtained through cookies and various tracking
              technologies, encompassing your IP address, domain name, browser version, operating system, traffic data, location data, web logs, and other communication data. We
              also monitor the resources you access on our website.
            </li>
            <li className="ml-8">
              Publicly Available Information: We may gather data available in the public domain or from publicly accessible sources, which may include information from social media
              websites.
            </li>
          </ul>
          <br />
          <p>We may process your personal information for specific legitimate or lawful purposes, including but not limited to the following:</p>
          <br />
          <ul className="list-disc">
            <li className="ml-8">
              Maintaining Accounts and Records: We utilize personal information for the maintenance of our accounts and records, ensuring accurate and up-to-date financial and
              operational information.
            </li>
            <li className="ml-8">
              Customizing and Enhancing Services and Communications: Personal data is employed to tailor and enhance our services and communications, providing you with a more
              personalized and efficient experience.
            </li>{' '}
            <li className="ml-8">
              Compliance with Applicable Laws: We collect and process information to adhere to the laws currently in force in India, as well as guidelines, orders issued by law
              enforcement agencies, court requirements, and regulatory obligations.
            </li>
            <li className="ml-8">
              Claims and Legal Defense: Your data may be utilized in the context of defending or asserting legal claims, safeguarding our rights and interests, and ensuring
              compliance with legal obligations.
            </li>
            <li className="ml-8">
              Facilitating Tax Planning and Advisory Services: Information is used to correspond with clients, beneficiaries, and claimants, facilitating the provision of tax
              planning and advisory services.
            </li>
            <li className="ml-8">Meeting Legal Obligations: We process personal information to fulfill our legal obligations as mandated by the relevant authorities.</li>
            <li className="ml-8">
              Protection of Vital Interests: Personal data may be processed to protect your vital interests or those of another individual, particularly in circumstances where such
              protection is paramount.
            </li>
            <li className="ml-8">
              Training and Quality Assurance: Information is used for training and quality assurance purposes, ensuring that our services meet high standards of excellence.
            </li>
            <li className="ml-8">
              Know Your Client/Customer (KYC) Compliance: We may collect and process data to fulfill KYC compliance requirements as per prevailing regulations.
            </li>
            <li className="ml-8">
              Performance of Contract: Personal information may be processed for the performance of a contract with you or to undertake necessary steps to enter into a contractual
              agreement.
            </li>
            <li className="ml-8">
              Performance of Public Interest Tasks: In certain instances, personal data may be processed in the performance of tasks carried out in the public interest or in the
              exercise of official authority, as mandated by relevant laws and regulations.
            </li>
            <li className="ml-8">
              Enhancing Network and Information Security: We collect data to bolster the security of our network and information systems, safeguarding them from potential threats
              and vulnerabilities.
            </li>
          </ul>
          <br />
          <p>We employ your personal information in the following manners:</p>
          <br />
          <ul className="list-disc">
            <li className="ml-8">
              Assessment for Tax Planning and Advisory Services: Your data is used to evaluate and determine whether tax planning or advisory services should be provided to you.
            </li>
            <li className="ml-8">
              Management of Income Tax Filing and Advisory Services: We use your personal information to efficiently manage tasks related to your income tax return filing and
              advisory services, including tax planning.
            </li>
            <li className="ml-8">
              Monitoring Calls and Transactions: Personal information is utilized to monitor calls and transactions, ensuring service quality, compliance with established
              procedures, fraud prevention, and adherence to the Information Technology Act, 2000, and other relevant laws currently in effect in India, along with TAX ALPHA's
              policies.
            </li>
            <li className="ml-8">
              Identity Verification: Your data is employed to identify you and conduct necessary identity checks as mandated by applicable laws and best practices.
            </li>
            <li className="ml-8">Payment Recovery: We process your personal information for the purpose of recovering any outstanding payments due to us.</li>
            <li className="ml-8">
              Service Analysis for Improvement: Personal data is analyzed to gain insights into the services we provide, enabling us to enhance our business operations and better
              serve our clients.
            </li>
            <li className="ml-8">
              Infrastructure and Operational Management: Your information is used to manage our infrastructure, streamline business operations, and ensure compliance with internal
              policies and procedures.
            </li>
            <li className="ml-8">Service Notifications: We may employ your data to notify you about any changes or updates to our services.</li>
            <li className="ml-8">
              Marketing Communications: Personal information is used for marketing purposes, allowing us to reach out to you through various channels, including postal mail, email,
              SMS, and phone.
            </li>
            <li className="ml-8">
              It is important to note that we will never process your personal data when such processing conflicts with your interests or rights. Your privacy and interests are of
              utmost importance to us.
            </li>
          </ul>
          <br />
          <p>
            Your consent to the processing of your personal information for specific purposes may be obligatory to comply with the Information Technology Act 2000, along with all
            its amendments, and other relevant laws in effect in India. In cases where your consent is required in accordance with these laws, we will request your consent. You
            retain the right to withdraw your consent for such processing at any time. From the moment you visit our website and engage with its content, it will be considered that
            you have granted your consent to abide by the privacy policies of Tax Alpha.
          </p>
          <br />
          <p>
            We are dedicated to upholding non-disclosure standards and all related agreements. We are committed to ensuring the safety and security of websites linked to us.
            However, it is important to note that we do not exercise control over third-party websites. Consequently, Tax Alpha may access and/or share your personal information
            with:
          </p>
          <br />
          <ul className="list-disc">
            <li className="ml-8">
              <b>Our Affiliates:</b> Tax Alpha may share information with its affiliated entities, including but not limited to Tax Alpha and associated affiliate offices as
              permitted by law. We may provide your personal data to our group companies, but solely for the purposes outlined in this privacy policy. Access to personal
              information within Tax Alpha and our affiliates is restricted to individuals who require access for our business operations. Your personal information will not be
              sold or shared with unauthorized third parties for their own commercial use without your explicit consent.
            </li>
            <li className="ml-8">
              <b>Service Providers:</b> Know Your Customer (KYC) Validation Agencies and Other Related Entities: In compliance with applicable laws, Tax Alpha may disclose your
              personal information to service providers, including but not limited to KYC validating agencies and other related entities. This disclosure may encompass your KYC
              details, such as KYC status validation, KYC documentation status, Aadhaar authentication, PAN validity checks, and retrieval of your KYC details, among others.
              Additionally, your information may be shared with various tax departments for accessing your Form 26AS, etc. Where permissible under applicable law, Tax Alpha may
              reveal your personal information to service providers who perform business functions on our behalf, regardless of their location within or outside India. These
              third-party service providers may utilize information about your website visits, excluding personally identifiable information such as your name, address, email, or
              telephone number, to display advertisements for goods and services that may interest you. They may also gather anonymous data regarding your interactions with our
              products and services and use this data to tailor advertisements. This process employs industry-standard technology such as pixel tags, which is commonly used by
              major websites, ensuring that no personally identifiable information is collected or used. These service providers are contractually bound to use your personal
              information appropriately and lawfully, with measures in place to safeguard your data.
            </li>
            <li className="ml-8">
              <b>Joint Marketing Arrangements:</b> Tax Alpha may share information with joint marketers with whom we have marketing arrangements
            </li>
            <li className="ml-8">
              <b>Persons Who Acquire Our Assets or Business:</b> If Tax Alpha transfers its rights, interests, or claims in any part of its business or assets, certain client
              information may be included in such transfers. In such cases, Tax Alpha will provide notification.
            </li>
            <li className="ml-8">
              <b>Other Third Parties: Governmental Authorities and Third Parties in Legal Proceedings:</b> Tax Alpha may disclose your personal information to third parties,
              including but not limited to courts of law and law enforcement agencies, in connection with investigations, proceedings, or inquiries by these parties, regardless of
              their location. This disclosure may also be made to facilitate compliance with regulatory requirements or dialogues with law enforcement agencies for the prevention,
              detection, investigation, prosecution, and punishment of cybercrime incidents or offenses. Tax Alpha will only proceed with such disclosures if government agencies
              ensure that the information obtained will not be published or shared with other parties. Additionally, aggregate information compiled from your personal data, which
              does not reveal individual identities, may be disclosed. Such information could include, for example, the total number of website visitors from a particular state or
              the average age of website users.
            </li>
            <li className="ml-8">
              <b>Transmission, Storage, and Security of Your Personal Information:</b> Tax Alpha is dedicated to safeguarding your personal data with the utmost care, diligence,
              and the latest technological advancements to prevent unauthorized access. We maintain physical, electronic, and procedural safeguards that are commercially reasonable
              to protect your personal information in accordance with the Information Technology Act, 2000, along with its amendments and other pertinent laws in force in India.
            </li>
          </ul>
          <br />
          <p>
            We will retain your personal information for a duration that is reasonably necessary for the purposes outlined in section 2 of this privacy policy. This retention
            period may entail keeping your personal information for an extended period when there's a potential need related to reviews conducted by agencies or for advisory
            services. Additionally, we may be obligated to retain your information to comply with tax, accounting, regulatory, or legal requirements.
          </p>
          <br />
          <p>
            To govern our data retention practices, we maintain a comprehensive data retention policy that is applied to all the data within our care. When your personal
            information is no longer necessary, we will ensure its secure deletion.
          </p>
          <br />
          <p>
            The Information Technology Act of 2000, along with its subsequent amendments, bestows upon you certain rights concerning the personal information we hold about you.
            These rights encompass:
          </p>
          <br />
          <ul className="list-disc">
            <li className="ml-8">Requesting further details regarding the use of your personal information.</li>
            <li className="ml-8">Obtaining a copy of the personal information we have on record for you.</li>
            <li className="ml-8">Correcting any inaccuracies in your personal information that we maintain.</li>
            <li className="ml-8">Requesting the deletion of any of your personal information that no longer has a lawful basis for usage.</li>
            <li className="ml-8">Stopping specific processing activities by withdrawing your consent, where applicable.</li>
            <li className="ml-8">
              Objecting to any processing based on our legitimate interests, unless our reasons for conducting such processing override any detriment to your data protection
              rights.
            </li>
            <li className="ml-8">Transferring your personal information to a third party in a standardized machine-readable format.</li>
            <li className="ml-8">
              In specific situations, we may need to restrict your rights to uphold the public interest, for example, in the prevention or detection of crime.
            </li>
          </ul>
          <br />
          <p>
            Please be aware that we may alter the content of our website or services without prior notice, which might lead to changes in our privacy policy. Therefore, we
            encourage you to periodically review it to stay informed about how we are utilizing personal information.
          </p>
          <br />
          <p>
            <b>
              Our primary aim is to keep you well-informed about the information we collect and how we employ it to provide you with our services, enhance your financial
              well-being, manage our operations, and make our services more useful. We remain committed to not selling or sharing your personal information with unauthorized third
              parties for their own commercial purposes without your explicit consent.
            </b>{' '}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
