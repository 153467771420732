import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import Category from './pages/Category';
import AdminLogin from './pages/AdminLogin';
import Dashboard from './pages/Dashboard';
import TaxCategory from './pages/TaxCategory';
import TaxPlans from './pages/TaxPlans';
import Messages from './pages/Messages';
import Testimonials from './pages/Testimonials';
import FAQ from './pages/FAQ';
import Faqs from './pages/Faqs';
import AdminRoute from './AdminRoute';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle('Tax-Alpha');

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-demo" element={<DemoProduct />} />
          <Route path="/about" element={<About />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />

          <Route path="/admin" element={<AdminLogin />} />

          <Route
            path="/dashboard"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
          <Route
            path="/taxCategory"
            element={
              <AdminRoute>
                <TaxCategory />
              </AdminRoute>
            }
          />
          <Route
            path="/taxPlans"
            element={
              <AdminRoute>
                <TaxPlans />
              </AdminRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <AdminRoute>
                <Messages />
              </AdminRoute>
            }
          />
          <Route
            path="/testimonials"
            element={
              <AdminRoute>
                <Testimonials />
              </AdminRoute>
            }
          />
          <Route
            path="/adminFaqs"
            element={
              <AdminRoute>
                <Faqs />
              </AdminRoute>
            }
          />
        </Routes>
      </ScrollToTop>
      <a href="https://wa.me/8959040813" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </Router>
  );
}

export default App;
