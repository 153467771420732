import React, { useEffect, useState } from 'react';
import { getMessages } from '../utils/services';
import NavBarAdmin from '../components/Navbar/NavBarAdmin';
const Messages = (props) => {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    await getMessages().then(async (response) => {
      if (response.data) {
        setMessages(response.data);
      } else if (response.error) {
        setMessages([]);
      }
    });
  };

  // const deletePlanFunc = async (plan) => {
  //   let payload = {
  //     _id: plan._id,
  //   };
  //   await deletePlan(payload).then(async (response) => {
  //     if (response.data) {
  //       fetchMessages();
  //     } else if (response.error) {
  //     }
  //   });
  // };

  return (
    <>
      <div>
        <NavBarAdmin />
      </div>
      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
        <div className="container mx-auto my-8 px-4 lg:px-1" data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Messages</h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <div className="ml-auto"></div>
          
          <div className="px-12 mt-10" data-aos="fade-down" data-aos-delay="600">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray100 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-10 dark:bg-gray-100 dark:text-black-400">
                  <tr>
                    <th scope="col" className="px-1 py-3">
                      Customer name
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Phone Number
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Message
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Plan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {messages.length > 0 &&
                    messages.map((msg) => {
                      return <tr className=" border-b bg-white dark:bg-gray-100 dark:border-black-700 text-gray-700 p-3">
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">{msg.name}</td>
                        <td>{msg.email}</td>
                        <td>{msg.phone_number}</td>
                        <td>{msg.message}</td>
                        <td>{msg.plan.length > 0 ? msg.plan[0].title : '-'}</td>
                      </tr>;
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
