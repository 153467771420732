import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <HashLink className="px-4 font-extrabold text-gray-500 " smooth to="/#about">
        Home
      </HashLink>
      <HashLink className="px-4 font-extrabold text-gray-500" smooth to="/#services">
        Services
      </HashLink>

      <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact#contact">
        Contact Us
      </HashLink>
      {/* <div className="relative"> */}
        <button onClick={toggleDropdown} className="group relative focus:outline-none px-4 font-extrabold text-gray-500 hover:text-blue-900">
          Knowledge Center
       

        <div className="absolute opacity-0 group-hover:opacity-100  right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
          <a href="/faq" className="block px-4 py-2 text-gray-500 hover:bg-gray-100">
            FAQ
          </a>
        </div>
        </button>
      {/* </div> */}

      {/* <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/faq">
        FAQ
      </HashLink> */}

      
    </>
  );
};

export default NavLinks;
