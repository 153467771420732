import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const Terms = () => {
  useDocTitle('Tax-Alpha');

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id="contact" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Terms and Conditions</h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <br />
          <p className='mt-4'>1. Acceptance of Terms</p>
          <br />
          <p>
            By accessing and using the Tax Alpha website (the "Website"), you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree to
            these Terms, please do not use the Website.
          </p>
          <br />
          <p>2. Use of the Website</p>
          <br />
          <p>
            You may use the Website solely for the purpose of learning about and contacting Tax Alpha for its services, which include Income Tax Filing Services, GST Filing
            Services, Accounting and Bookkeeping Services, and Business Registration and Incorporation Services. You agree not to use the Website for any unlawful purpose or in any
            way that might harm, damage, or disparage any other party.
          </p>
          <br />
          <p>3. Intellectual Property</p>
          <br />
          <p>
            All content on the Website, including but not limited to text, graphics, logos, images, and software, is the property of Tax Alpha and is protected by copyright,
            trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any content on the Website without prior
            written consent from Tax Alpha.
          </p>
          <br />
          <p>4. Disclaimer of Warranties</p>
          <br />
          <p>
            The Website is provided on an "as is" and "as available" basis. Tax Alpha makes no warranties, express or implied, regarding the Website's operation or the information,
            content, or materials included on the Website. To the fullest extent permitted by law, Tax Alpha disclaims all warranties, express or implied, including but not limited
            to implied warranties of merchantability and fitness for a particular purpose.
          </p>
          <br />
          <p>5. Limitation of Liability</p>
          <br />
          <p>
            In no event shall Tax Alpha be liable for any damages of any kind arising from the use of the Website, including but not limited to direct, indirect, incidental,
            punitive, and consequential damages. Tax Alpha's liability, under any circumstances, shall be limited to the amount of fees received for the services provided and only
            in cases where an error or mistake occurred due to the negligence of Tax Alpha. Tax Alpha shall not be held responsible for any missed due dates, as it is not solely
            the responsibility of Tax Alpha to remind clients of such dates; it is also the client's duty to be aware of and adhere to due dates. Furthermore, Tax Alpha shall not
            be liable for any errors resulting from the client's failure to provide necessary data.
          </p>
          <br />
          <p>6. Password and Security</p>
          <br />
          <p>
            Tax Alpha may request the password for relevant government websites necessary to perform the intended services. We commit to taking reasonable security precautions to
            protect any passwords and user IDs associated with your use of the Services. These precautions will include measures to prevent unauthorized access and ensure the
            confidentiality of your information. By providing such credentials, you agree to allow Tax Alpha to use them solely for the purpose of delivering the agreed services.
          </p>
          <br />
          <p>7. Refund Policy</p>
          <br />
          <p>
            Both the Client and Tax Alpha have the right to terminate any Services for any reason, including the ending of Services that are already underway. No refunds shall be
            offered where a Service is deemed to have begun and is, for all intents and purposes, underway. Any monies that have been paid to Tax Alpha which constitute payment in
            respect of the provision of unused Services shall be refunded at the discretion of Tax Alpha.
          </p>
          <br />
          <p>8. Correctness of Returns Filed or Submission</p>
          <br />
          <p>
            You are required to review your Income Tax Return to ensure its correctness. You will not hold Tax Alpha responsible for any issue that arises from incorrect Income Tax
            Return filing. Additionally, you explicitly agree that Tax Alpha will not be held responsible for any error caused by bugs in the system/server or due to any other
            technical reason.
          </p>
          <br />
          <p>9. Links to Third-Party Websites</p>
          <br />
          <p>
            The Website may contain links to third-party websites for your convenience. Tax Alpha does not endorse any such third-party websites or the information, materials,
            products, or services contained on or accessible through the third-party websites. Your access and use of linked third-party websites are solely at your own risk.
          </p>
          <br />
          <p>10. Changes to Terms</p>
          <br />
          <p>
            Tax Alpha reserves the right to modify these Terms at any time. Any changes to the Terms will be effective immediately upon posting on the Website. Your continued use
            of the Website following the posting of changes constitutes your acceptance of such changes.
          </p>
          <br />
          <p>11. Privacy Policy</p>
          <br />
          <p>
            Your use of the Website is also governed by our Privacy Policy, which can be accessed at [Privacy Policy Link]. Please review our Privacy Policy to understand our
            practices regarding the collection, use, and disclosure of your personal information.
          </p>
          <br />
          <p>12. Governing Law</p>
          <br />
          <p>
            These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these Terms shall be subject to
            the exclusive jurisdiction of the courts located in Mumbai, India.
          </p>
          <br />
          <p>13. Contact Information</p>
          <br />
          <p>If you have any questions or concerns about these Terms, please contact us at:</p>
          <br />
          <br />
          <p>
            <b>Tax Alpha
            <br />
            Mumbai
            <br />
            08959040813
            <br />
            taxalphaconsulting@gmail.com
            <br />
            </b>
          </p>
          <p>
            <br />
            By using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
          </p>{' '}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
