import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { getFaqs } from '../utils/services';

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    await getFaqs().then(async (response) => {
      if (response.data) {
        setFaqs(response.data);
      } else if (response.error) {
        setFaqs([]);
      }
    });
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id="contact" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Frequently Asked Questions</h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>

          <section className="dark:bg-gray-100 dark:text-gray-800 mt-5">
            <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
              <h6 className="mb-12 text-4xl font-bold leading-none text-center sm:text-5xl">How it works</h6>
              {/* <h6 className="sm:px-8 lg:px-12 xl:px-32">Section title</h6> */}

              <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 dark:divide-gray-300">
                {faqs.map((faq,i) => {
                  return (
                    <details key={i+"faq"}>
                      <summary className="py-2 outline-none cursor-pointer focus:underline">{faq.question}</summary>
                      <div className="px-4 pb-4">
                        <p>{faq.answer}</p>
                      </div>
                    </details>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
